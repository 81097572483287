var G = require('./core.js');
var Fingerprint2 = require('./vendor/fp.js');
var constants = require('./constants.js');

// create new Grin instance
var _GrinLib = new G();

function grinBootstrap(fingerprint) {
    _GrinLib.init({}, fingerprint);

    // if Grin has been called manually then honor it
    if (window[constants.GRIN_INSTANCE_NAME] && window[constants.GRIN_INSTANCE_NAME].length) {
        for (var _gI in window.Grin) {
            _GrinLib[window.Grin[_gI][0]].apply(_GrinLib, window.Grin[_gI].slice(1));
        }
    }

    // attach the library to Grin
    window.Grin = _GrinLib;

    // run the instance loaded method
    window.Grin.config.loaded();
}

// generate the fingerprint
setTimeout(function () {
    if(_GrinLib.getSetting('DISABLE_FINGERPRINT_GENERATION') === true) {
        var fingerprint = "";
        grinBootstrap(fingerprint);
    } else {
        Fingerprint2.get({
            excludes: {
                'fonts': true,
                'adBlock': true,
                'enumerateDevices': true,
                'pixelRatio': true,
                'doNotTrack': true,
                'fontsFlash': true
            }
        }, function (components) {
            var values = components.map(function (component) {
                return component.value
            });
        
            _GrinLib.fetchMiscInfo(function (data) {
        
                values.push(data.data);
        
                var fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
            
                grinBootstrap(fingerprint);
            });
        });
    }
}, 750);