var Object = require('./vendor/assign.js');
var GrinUtils = require('./utils.js');
var constants = require('./constants.js');

// production
var GRIN_DEFAULT_CONFIG = {
    'debug': DEBUG,
    'domain': DOMAIN,
    'api_host': API_HOST,
    'ip': IP,
    'loaded': LOADED,
};

/**
 * The main Grin object
 *
 * @constructor
 */
var G = function () {
};

/**
 * Initialize token and config
 *
 * @param {object} config
 * @param {string} fingerprint
 * @return {object}
 */
G.prototype.init = function (config, fingerprint) {
    this.config = Object.assign(GRIN_DEFAULT_CONFIG, config);
    this.fingerprint = fingerprint;

    this.debugLine('grin:init');

    // let grinApp = this;

    // if ((typeof window.Shopify !== "undefined") && (typeof window.Shopify.loadFeatures === "function")) {
    //     window.Shopify.loadFeatures([
    //             {
    //                 name: 'consent-tracking-api',
    //                 version: '0.1',
    //             }
    //         ],
    //         function () {
    //             grinApp.initCookieBanner(grinApp);
    //         }
    //     );
    // } else {
        this.start();
    // }
};


G.prototype.createBanner = function (grinApp) {
    var cookieDiv = document.createElement('div');
    cookieDiv.innerHTML = `<span>This website uses cookies to ensure you get the best experience on our website.</span><button id="grin-cookie-decline" style="margin-left: 1em;">Decline</button><button  id="grin-cookie-accept" style="margin-left: 1em;">Accept</button>`;
    cookieDiv.id = 'grin-cookie-banner';

    cookieDiv.style.width = '100%';
    cookieDiv.style.padding = ' 2% 0';
    cookieDiv.style.background = ' white';
    cookieDiv.style.boxShadow = ' 12px 12px 25px #444444aa';
    cookieDiv.style.borderBottom = ' .5px solid #4D4DFE';
    cookieDiv.style.textAlign = ' center';
    cookieDiv.style.position = 'fixed';
    cookieDiv.style.bottom = '0%';
    cookieDiv.style.zIndex = '10000';

    document.body.prepend(cookieDiv);

    const cookieAcceptBtn = document.getElementById('grin-cookie-accept');
    const cookieDeclineBtn = document.getElementById('grin-cookie-decline');

    cookieDeclineBtn.style.borderRadius = '5%';
    cookieAcceptBtn.style.borderRadius = '5%';
    cookieDeclineBtn.style.padding = '7px';
    cookieAcceptBtn.style.padding = '7px';

    cookieAcceptBtn.style.background = 'black';
    cookieAcceptBtn.style.color = 'white';

    cookieAcceptBtn.addEventListener('click', function () {
        console.debug('trackingConsentAccepted');
        window.Shopify.customerPrivacy.setTrackingConsent(true, function () {
            grinApp.hideBanner(grinApp);
            grinApp.start();
        });
    });

    cookieDeclineBtn.addEventListener('click', function () {
        console.debug('trackingConsentDeclined');
        window.Shopify.customerPrivacy.setTrackingConsent(false, function () {
            grinApp.hideBanner(grinApp);
        });
    });
}
G.prototype.getBannerEl = function() {
    return document.getElementById('grin-cookie-banner');
}


G.prototype.hideBanner = function (grinApp) {
    grinApp.getBannerEl().style.display = 'none';
}

G.prototype.initCookieBanner = function (grinApp) {
    if (window.Shopify.customerPrivacy && window.Shopify.customerPrivacy.getTrackingConsent() === 'no_interaction') {
        grinApp.createBanner(grinApp);
    } else if (window.Shopify.customerPrivacy.getTrackingConsent() === 'no') {
        console.debug("no cookie storage allowed");
    } else {
        console.debug("cookie storage already accepted");
        grinApp.start();
    }
}

/**
 * Add push method to handle conversions after script has loaded
 *
 * @param {array} item
 */
G.prototype.push = function (item) {
    this[item[0]].apply(this, item.slice(1));
};

G.prototype.getSetting = function (setting) {
    if (typeof window.GrinSettings != "undefined"
        && typeof window.GrinSettings[setting] != "undefined"
    ) {
        return window.GrinSettings[setting];
    } else {
        return null;
    }
};

/**
 * Run the start method
 */
G.prototype.start = function () {

    // get the data from the query string
    var data = GrinUtils.paramsFromQuery();

    this.debugLine('grin:start');

    if (this.getSetting("API_TOKEN")) {
        this.token = this.getSetting("API_TOKEN");
    } else if (data.platform == 'grin') {
        this.token = data.token;
    } else {
        this.token = GrinUtils.cookiesRead('_gToken');
    }

    // we have the platform as grin so we can set the params
    if (data.platform == 'grin') {
        this.link_id = data.link_id || data.lid;
        this.contact_id = data.contact_id || data.cid;
        this.attribution_window = data.attribution_window;
    } else {
        this.link_id = GrinUtils.cookiesRead('_gLink');
        this.contact_id = GrinUtils.cookiesRead('_gContact');
        this.attribution_window = GrinUtils.cookiesRead('_gAttributionWindow');
    }

    // write cookies if visit came from link
    if (data.platform == 'grin') {

        // Length of cookies in days.
        var attributionWindow = this.attribution_window || 30;

        GrinUtils.cookiesWrite('_gToken', this.token, attributionWindow);
        GrinUtils.cookiesWrite('_gLink', this.link_id, attributionWindow);
        GrinUtils.cookiesWrite('_gContact', this.contact_id, attributionWindow);
        GrinUtils.cookiesWrite('_gAttributionWindow', this.attribution_window, attributionWindow);

        this.setFingerprint();
    }

    this.debugLine('grin:token: ' + this.token + ' contact: ' + this.contact_id + ' link: ' + this.link_id + ' attribution window: ' + this.attribution_window);

    // send a view event
    this.view({});
};

/**
 * Send a GET request
 *
 * @param params
 */
G.prototype.send = function (params) {
    params.token = this.token;
    params.link_id = this.link_id;
    params.contact_id = this.contact_id;
    params.fingerprint = this.fingerprint;
    params.user_agent = window.navigator.userAgent;

    params.referrer = document.referrer;
    params.url = window.location.origin + window.location.pathname;

    var payload = GrinUtils.encode(JSON.stringify(params));

    var img = new Image();
    img.src = 'https://' + this.config.domain + '/collect?payload=' + payload;

    img.onload = function () {
    };
    img.onerror = function () {
    };
};

G.prototype.setFingerprint = function () {
    if (!this.token || !this.contact_id || !this.link_id) {
        return;
    }

    var params = {};

    params.event = 'fingerprint';
    params.attribution_window = this.attribution_window;

    this.send(params);

    this.debugLine('grin:fingerprint', params);
};

G.prototype.fetchFingerprint = function (trackingDomain, callback) {
    var self = this;

    var xhr = new XMLHttpRequest();
    xhr.open('GET', 'https://' + this.config.domain + '/fingerprint/' + this.fingerprint + '?tracking_domain=' + trackingDomain);
    xhr.onload = function () {
        if (xhr.status === 200) {
            var response = JSON.parse(xhr.responseText);
            callback(response);
        }
    };
    xhr.send();
};

G.prototype.fetchMiscInfo = function(callback) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', 'https://www.cloudflare.com/cdn-cgi/trace');
    xhr.onload = function() {
        if (xhr.status === 200) {
            callback({
                data: xhr.responseText.split('\n').filter(function(text){
                    return ((text[0] + text[1]) === 'ip');
                })[0]
            })
        } else {
            callback({
                data: null
            })
        }
    }

    xhr.send();
}
/**
 * Send an event
 *
 * @param {object} params
 */
G.prototype.view = function (params) {
    if (!this.token || !this.contact_id || !this.link_id) {
        return;
    }

    params = params || {};
    params.event = 'view';
    this.send(params);

    this.debugLine('grin:view', params);
};

/**
 * Send an event
 *
 * @param {object} params
 */
G.prototype.track = function (params) {
    if (!this.token || !this.contact_id || !this.link_id) {
        return;
    }

    params = params || {};
    params.event = 'track';
    this.send(params);

    this.debugLine('grin:track', params);
};

/**
 * Set window.Grin.third_party_checkout
 *
 * {string} thirdPartyCheckout
 */
G.prototype.setThirdPartyCheckout = function (thirdPartyCheckout) {
    this.third_party_checkout = thirdPartyCheckout;
};

/**
 * Send a conversion
 *
 * {object} params
 */
G.prototype.conversion = function (total, params) {
    params = params || {};
    params.total = total;
    params.event = 'conversion';

    // Checkout big C is the page object unfortunately that is the differentiator which will let us know if this is visited
    //  via the thank_you page (the one we want to send this payload for and the order status page where we don't want
    //  to send a conversion for).  NOTE: shopify 3rd party checkouts skip this.

    var disable_fingerprints = false;

    if (this.getSetting("DISABLE_FINGERPRINTS") === true) {
        disable_fingerprints = true;
    }

    if ((!this.token || !this.contact_id || !this.link_id) && disable_fingerprints) {
        return;
    }

    // GN-12399
    if (typeof Shopify !== 'undefined') {
        if (
            (typeof this.third_party_checkout === 'string') &&
            (this.third_party_checkout.toLowerCase() === 'recharge') &&
            !('Checkout' in Shopify)
        ) {
            Shopify.Checkout = { step: 'thank_you' };
            Shopify.checkout.subtotal_price = amount;
        }

        if (
            !('checkout' in Shopify)
            || !('Checkout' in Shopify)
            || !('step' in Shopify.Checkout)
            || Shopify.checkout === null
            || Shopify.Checkout.step !== 'thank_you'
        ) {
            // Not a conversion
            return;
        }

        params.total          = Shopify.checkout.subtotal_price;
        params.currency       = params.currency || Shopify.checkout.currency;
        params.customer_id    = Shopify.checkout.customer_id;
        params.order_id       = Shopify.checkout.order_id;
        params.checkout_token = Shopify.checkout.token;
    }

    var self = this;

    if (!this.token || !this.contact_id || !this.link_id) {

    	// send the conversion with
        this.fetchFingerprint(params.tracking_domain, function(response) {
            self.link_id            = response.link_id;
            self.contact_id         = response.contact_id;
            self.token              = response.token;
            self.attribution_window = response.attribution_window;

            self.send(params);
        });
        return;
    }

    this.send(params);

    this.debugLine('grin:conversion', params);
};

/**
 * Set the given configuration
 * @param {object} params
 */
G.prototype.configure = function (params) {
    if (!window[constants.GRIN_INSTANCE_NAME] && GRIN_DEFAULT_CONFIG) {
        GRIN_DEFAULT_CONFIG = Object.assign(GRIN_DEFAULT_CONFIG, params);
    } else {
        this.config = Object.assign(this.config, params);
    }
    this.debugLine('grin:configure', params, this.config);
};

/**
 * Send a debug line to the console
 *
 * @param {string} output
 * @return {void}
 */
G.prototype.debugLine = function (output) {
    if (this.config.debug || document.location.origin == 'https://grin-production.myshopify.com') {
        console.log(output);
    }
};

module.exports = G;
