var fetch = require('./vendor/fetch').fetch;
var GrinUtils = {}, _gBreaker = {};

/**
 * Trim the given string
 *
 * @param str
 * @return {string | void}
 */
GrinUtils.trim = function(str) {
    return str.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
};

/**
 * Loop through through the object and apply the interator
 *
 * @param {array|object} obj
 * @param {function} iterator
 * @param {*} context
 */
GrinUtils.each = function(obj, iterator, context) {
    if (obj === null || obj === undefined) {
        return;
    }
    if (Array.prototype.forEach && obj.forEach === Array.prototype.forEach) {
        obj.forEach(iterator, context);
    } else if (obj.length === +obj.length) {
        for (var i = 0, l = obj.length; i < l; i++) {
            if (i in obj && iterator.call(context, obj[i], i, obj) === _gBreaker) {
                return;
            }
        }
    } else {
        for (var key in obj) {
            if (hasOwnProperty.call(obj, key)) {
                if (iterator.call(context, obj[key], key, obj) === _gBreaker) {
                    return;
                }
            }
        }
    }
};

/**
 * Base 64 encode the string
 *
 * @param {string} str
 * @return {string}
 */
GrinUtils.encode = function(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
};

/**
 * Query param helpers
 *
 * @type {object}
 */
GrinUtils.paramsFromQuery = function() {
    var a = window.location.search.substr(1).split('&');
    var b = {};
    for (var i = 0; i < a.length; ++i) {
        var p = a[i].split('=', 2);
        if (p.length === 1) {
            b[p[0]] = "";
        } else {
            b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
        }
    }
    return b;
};

/**
 * Return a query string from the given data object
 *
 * @param {object} data
 * @return {string}
 */
GrinUtils.paramsToQuery =  function(data) {
    return Object.keys(data).map(function(key) {
        return key + '=' + data[key]
    }).join('&');
};


function setCookie(name, value, expires, domain) {
    document.cookie = name + "=" + value + expires + "; path=/; domain=" + domain + ";";
}

/**
 * Write to cookies
 *
 * @param {string} name
 * @param {string} value
 * @param {number} days
 */
GrinUtils.cookiesWrite = function(name, value, days) {
    var expires;

    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    else {
        expires = "";
    }

    var parts = window.location.hostname.split('.');

    // Cover all the possible domains. This will generate invalid domains. They will fail silently in the browser.
    parts.reverse().reduce(function(urlParts, urlPart) {
        var combinedUrl = urlPart + urlParts;
        var prefixCombinedUrl = '.' + urlPart + urlParts;

        setCookie(name, value, expires, combinedUrl);
        setCookie(name, value, expires, prefixCombinedUrl);

        return prefixCombinedUrl;
    }, '');
};

/**
 * Read a cookie by name
 *
 * @param {string} name
 * @return {string}
 */
GrinUtils.cookiesRead = function(name) {
    var allCookie = '' + document.cookie;
    var index = allCookie.indexOf(name);
    if (name === undefined || name === '' || index === -1) return '';
    var ind1 = allCookie.indexOf(';', index);
    if (ind1 === -1) ind1 = allCookie.length;
    return unescape(allCookie.substring(index + name.length + 1, ind1));
};

/**
 * Remove a cookie by name
 * @param {string} name
 */
GrinUtils.cookiesRemove = function(name) {
    if (this.cookiesRead(name)) {
        this.cookiesWrite(name, '', -1);
    }
};

/**
 * Make a get request
 *
 * @param {string} endpoint
 * @param {object|null} data
 * @param {function} success
 * @param {function} failure
 */
GrinUtils.get = function(endpoint, data, success, failure) {
    if(data && Object.keys(data).length) {
        endpoint = endpoint + '?' + GrinUtils.paramsToQuery(data);
    }
    fetch(endpoint).then(function(res) {
        success(res);
    }).catch(function (res) {
        failure(res);
    });
};

/**
 * Make a post request
 *
 * @param {string} endpoint
 * @param {object|null} data
 * @param {function} success
 * @param {function} failure
 */
GrinUtils.post = function(endpoint, data, success, failure) {
    fetch(endpoint, {
        body: JSON.stringify(data),
        headers: {'Content-Type': 'application/json'}
    }).then(function(res) {
        success(res);
    }).catch(function (res) {
        failure(res);
    });
};

/**
 * Whether the given variable is an array
 *
 * @type {*} obj
 * @return {boolean}
 */
GrinUtils.isArray = Array.isArray || function(obj) {
    return toString.call(obj) === '[object Array]';
};

/**
 * Whether the given variable is a function
 *
 * @type {*} obj
 * @return {boolean}
 */
GrinUtils.isFunction = function(f) {
    try {
        return /^\s*\bfunction\b/.test(f);
    } catch (x) {
        return false;
    }
};

/**
 * Local storage access
 *
 * @type {object}
 */
GrinUtils.lsAvailable = function() {
    var check = 'check';
    try {
        localStorage.setItem(check, check);
        localStorage.removeItem(check);
        return true;
    } catch (e) {
        return false;
    }
};

/**
 * Run the given function when the DOM is read
 *
 * @param {function} fn
 */
GrinUtils.ready = function(fn) {
    if (document.readyState !== 'loading') {
        fn();
    } else if (window.addEventListener) {
        window.addEventListener('DOMContentLoaded', fn);
    } else {
        window.attachEvent('onreadystatechange', function() {
            if (document.readyState !== 'loading') {
                fn();
            }
        });
    }
};

module.exports = GrinUtils;
